import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const SectionInfo = ({ topic, detail }) => {
  return (
    <section className='pt-5 pb-0'>
      <Container className='text-center px-5 mb-0 mb-md-5'>
        <div className='fs-custom-1 fw-bold text-uppercase tx-color-dark tx-spacing-10 lh-12 mb-3 mb-md-5'>{topic}</div>
        <Row>
          <Col className="p-0 px-2 px-xl-5">
            <p className='fs-custom-5 lh-12 fw-light mb-3 mb-md-5 d-hide-br d-sm-show-br' dangerouslySetInnerHTML={{ __html: detail }}></p>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default SectionInfo