import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, NavDropdown, Nav, Offcanvas, Container } from 'react-bootstrap';
import Logo from '../Images/logo/logo-wt.png';
import { urlApi } from '../Constants/Global';
import axios from 'axios';

const MainMenu = ({ page, path, navColor, accom, location, lang }) => {
  const [activeKey, setActiveKey] = useState('/');
  const expand = 'xl';
  const menuClass = 'fs-6 fw-bold text-white px-3 px-xl-2 text-uppercase tx-spacing-10';
  const downdownClass = 'fs-6 fw-bold text-white px-0 px-xl-2 text-uppercase tx-spacing-10';
  const activeClass = 'active';

  const navDropdownAcc = ["/Glamping", "/OnGridCamping", "/OffGridCamping"];
  const navDropdownLoc = ["/Eden", "/Stellar", "/Rayong"];

  const handleSelect = (selectKey) => {
    setActiveKey(selectKey);
  }

  const [scrollPosition, setScrollPosition] = useState(0);

  // Function to be called on scroll
  const handleScroll = () => {
    // Update the state with the current scroll position
    setScrollPosition(window.scrollY);

    if (scrollPosition > 100) {
      console.log(scrollPosition);
    }
  };

  const [isOffcanvasOpen, setOffcanvasOpen] = useState(false);

  const toggleOffcanvas = () => {
    setOffcanvasOpen(!isOffcanvasOpen);
  };

  const handleLinkClick = () => {
    // Close the offcanvas when a link is clicked
    setOffcanvasOpen(false);
  };

  const isMobileSize = window.innerWidth <= 1199;
  const [isAccommodationDropdownOpen, setAccommodationDropdownOpen] = useState(false);
  const [isLocationDropdownOpen, setLocationDropdownOpen] = useState(false);

  const toggleAccommodationDropdown = () => {
    console.log("clicked", isAccommodationDropdownOpen);
    setAccommodationDropdownOpen(!isAccommodationDropdownOpen);
  };

  const toggleLocationDropdown = () => {
    console.log("clicked", isLocationDropdownOpen);
    setLocationDropdownOpen(!isLocationDropdownOpen);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array to run only on mount and unmount

  const changeLanguage = async (newLang) => {
    try {
      // Create a new FormData object
      const formData = new FormData();
      formData.append('lang', newLang); // Append form data (key-value pair)

      console.log("changeLanguage", newLang);

      var url = '/' + urlApi + '/?module=common&call=change-language';

      fetch(url, {
        method: 'POST',
        body: formData
      })
        .then(res => res.json())
        .then(result => {
          console.log("changedLanguage", result.returnStatus.code, result);

          if (result.returnStatus.code === "0000") {
            window.location.reload();
          }
        });
    } catch (error) {
      console.error('Error changing language:', error);
    }
  };

  const navbarClass = scrollPosition > 100 ? 'bg-custom-primary' : '';

  return (
    <>
      <Navbar expand={expand} id="main-menu" fixed='top' className={navColor === 'transparent' ? 'pt-0 pb-0 pt-xl-3 pb-xl-3 ' + navbarClass : (navColor === 'primary' ? 'pt-2 pb-2 pt-xl-3 pb-xl-3 bg-custom-primary' : 'pt-2 pb-2 pt-xl-3 pb-xl-3 bg-custom-secondary')}>
        <Container fluid>
          <Navbar.Brand as={Link} to="/">
            <img src={Logo} className="logo mx-3" alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={toggleOffcanvas} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="end"
            show={isOffcanvasOpen}
            onHide={() => setOffcanvasOpen(false)}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-2 pe-xl-3">
                <Nav.Link className={`${menuClass} ` + (path === '/' ? activeClass : '')} as={Link} to="/" onClick={handleLinkClick}>Home</Nav.Link>
                <NavDropdown
                  className={`${downdownClass} custom-nav-dropdown px-0 ` + (navDropdownAcc.indexOf(path) !== -1 ? activeClass : '') + (isLocationDropdownOpen && isMobileSize ? ' d-none' : '')}
                  title="Accommodations" id="basic-nav-dropdown"
                  onClick={toggleAccommodationDropdown}
                >
                  {accom.map((submenu, index) => (
                    <NavDropdown.Item key={index} className="fw-bold" as={Link} to={"/Accommodation/" + submenu.row_slug} onClick={handleLinkClick}>
                      {submenu.name}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
                <NavDropdown className={`${downdownClass} custom-nav-dropdown px-0 ` + (navDropdownLoc.indexOf(path) !== -1 ? activeClass : '') + (isAccommodationDropdownOpen && isMobileSize ? ' d-none' : '')} title="Locations" id="basic-nav-dropdown2" onClick={toggleLocationDropdown}>
                  {location.map((submenu, index) => (
                    <NavDropdown.Item key={index} className="fw-bold" style={{ width: '215px' }} as={Link} to={"/Location/" + submenu.row_slug} onClick={handleLinkClick}>
                      <span className='fw-light' style={{ fontSize: '12px' }}>{submenu.location_name}</span>
                      <div>{submenu.name}</div>
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
                <Nav.Link className={`${menuClass} ` + (path === '/About' ? activeClass : '') + ((isAccommodationDropdownOpen || isLocationDropdownOpen) && isMobileSize ? ' d-none' : '')} as={Link} to="/About" onClick={handleLinkClick}>About Us</Nav.Link>
                <Nav.Link className={`${menuClass} ` + (path === '/Contact' ? activeClass : '') + ((isAccommodationDropdownOpen || isLocationDropdownOpen) && isMobileSize ? ' d-none' : '')} as={Link} to="/Contact" onClick={handleLinkClick}>Contact</Nav.Link>
                <Nav.Link className={`${menuClass} ms-2 px-3 bg-color-secondary tx-color-primary booking-button` + ((isAccommodationDropdownOpen || isLocationDropdownOpen) && isMobileSize ? ' d-none' : '')} as={Link} to="/Booking" onClick={handleLinkClick}>
                  Book Now
                </Nav.Link>
                {lang === 'en' ?
                  <>
                    <Nav.Link className='ps-4 pe-0 text-white d-none d-xl-inline-block'>EN | </Nav.Link>
                    <Nav.Link className='text-white opacity-50 d-none d-xl-inline-block' onClick={() => changeLanguage('th')}>TH</Nav.Link>
                    <Nav.Link className='text-white px-3 d-inline-block d-xl-none'>
                      EN | <div className='d-inline-block opacity-50' onClick={() => changeLanguage('th')}>TH</div>
                    </Nav.Link>
                  </>
                  :
                  <>
                    <Nav.Link className='ps-4 pe-0 text-white opacity-50 d-none d-xl-inline-block' onClick={() => changeLanguage('en')}>EN</Nav.Link>
                    <Nav.Link className='text-white d-none d-xl-inline-block'> | TH</Nav.Link>
                    <Nav.Link className='text-white px-3 d-inline-block d-xl-none'>
                      <div className='d-inline-block opacity-50' onClick={() => changeLanguage('en')}>EN</div> | TH
                    </Nav.Link>
                  </>
                }
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  )
}

MainMenu.defaultProps = {
  navColor: 'transparent',
  accom: [],
  location: []
}

export default MainMenu