import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { urlApi } from '../Constants/Global';
// import Sign1 from '../Images/mockup/new-icon/logo-eden+chiangmai.png';
// import Sign2 from '../Images/mockup/new-icon/logo-stellar+maewang.png';
// import Sign3 from '../Images/mockup/new-icon/logo-coasta+rayong.png';

import Sign1 from '../Images/final/10-booking/eden.png';
import Sign2 from '../Images/final/10-booking/stellar.png';
import Sign3 from '../Images/final/10-booking/rayong.png';

import Photo1 from '../Images/final/1-home/EDEN.png';
import Photo2 from '../Images/final/1-home/STELLAR.png';
import Photo3 from '../Images/final/1-home/COASTA.png';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';

const SectionLocation = ({ contents, topic, detail }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeImg, setActiveImg] = useState(0);
  const [signs, setSigns] = useState(contents);

  const storage = urlApi + '/storage/media/';
  const srcVideo = storage + 'video.mp4';

  console.log("SectionLocation", signs, contents);

  const prev = () => {
    const current = (activeIndex - 1 + signs.length) % signs.length;
    console.log(current, activeIndex);
    moveElementToFirst(current);
    setActiveIndex(activeIndex);
  };

  const next = () => {
    const current = (activeIndex + 1) % signs.length;
    console.log(current, activeIndex);
    moveElementToLast(activeIndex);
    setActiveIndex(current);
  };

  const moveElementToFirst = (index) => {
    const newArray = [...signs];
    const movedElement = newArray.splice(index, 1)[0];
    newArray.unshift(movedElement);
    setSigns(newArray);
  };

  const moveElementToLast = (index) => {
    const newArray = [...signs];
    const movedElement = newArray.splice(index, 1)[0];
    newArray.push(movedElement);
    setSigns(newArray);
  };

  // Update signs state when contents prop changes
  useEffect(() => {
    setSigns(contents);
  }, [contents, activeImg]);

  return (
    <section className='pt-5 pb-0 pb-md-5'>
      <Container fluid className='text-center'>
        <Row>
          <Col md={{ span: 12, offset: 0 }} className='px-5 px-xl-0'>
            <div className='fs-custom-3 fw-bold text-uppercase tx-color-dark tx-spacing-10 mb-2 mb-md-4'>{topic}</div>
            <p className='fs-custom-5 lh-12 fw-light d-hide-br d-sm-show-br' dangerouslySetInnerHTML={{ __html: detail }}></p>
          </Col>
        </Row>
        <Row className="mt-5 mb-5 pt-0 pt-md-5 pb-0 pb-md-5">
          <Col xs={1} md={1}>
            <div className='position-relative w-100 h-100 cursor-pointer' onClick={next}>
              <IoChevronBack size={36} className='position-absolute top-50 start-50 translate-middle' />
            </div>
          </Col>
          <Col xs={10} md={10}>
            <Row>
              <Col md={1} className='order-1'></Col>
              {signs.map((item, index) => (
                index === 0 ?
                  <Col className='order-3' key={index}>
                    <Link to={'/Location/' + item?.row_slug}>
                      <img src={storage + item?.header_icon2} className='mx-3 mx-md-5 home-location-sign active' />
                    </Link>
                  </Col> : index === 1 ? <Col className='order-2' key={index}>
                    <div className='position-relative w-100 h-100'>
                      <Link to={'/Location/' + item?.row_slug}>
                        <img src={storage + item?.header_icon2} className='position-absolute top-50 start-50 translate-middle home-location-sign' />
                      </Link>
                    </div>
                  </Col> : <Col className='order-4' key={index}>
                    <div className='position-relative w-100 h-100'>
                      <Link to={'/Location/' + item?.row_slug}>
                        <img src={storage + item?.header_icon2} className='position-absolute top-50 start-50 translate-middle home-location-sign' />
                      </Link>
                    </div>
                  </Col>
              ))}
              <Col md={1} className='order-5'></Col>
            </Row>
          </Col>
          <Col xs={1} md={1}>
            <div className='position-relative w-100 h-100 cursor-pointer' onClick={prev}>
              <IoChevronForward size={36} className='position-absolute top-50 start-50 translate-middle' />
            </div>
          </Col>
        </Row>
        
        <Row>
          <Col className='p-0 position-relative'>
            <img key={activeImg} src={storage + signs[activeImg]?.explore_img} className="w-100" />
            <Link to={'/Location/' + signs[activeImg]?.row_slug}>
              <div className='position-absolute bottom-0 ms-3 ms-md-5 text-uppercase tx-spacing-10 bg-color-secondary tx-color-primary rounded-bs-0 rounded-be-0 btn-explore'>
                Explore
              </div>
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

SectionLocation.defaultProps = {
  contents: []
}

export default SectionLocation